<template>
  <Spinner v-if="isEventLoading" />
  <div
    v-else
    class="audit-log"
  >
    <div class="audit-log-header">
      <div class="audit-log-header-name">
        <h5 v-if="marketsList">
          {{ eventName }}
        </h5>
      </div>
      <div class="audit-log-header-info">
        <div class="audit-log-header-info-text">
          <Icon
            name="calendar"
          />
          {{ date }}
        </div>
        <div class="dot-separator">
          •
        </div>
        <div class="audit-log-header-info-text">
          <Icon
            name="clock"
          />
          {{ time }}
        </div>
      </div>
    </div>
    <div class="audit-log-filters-wrapper">
      <div class="audit-log-filter">
        <SearchSelect
          :model-value="selectedMarket"
          :options="marketsList"
          @update:model-value="selectMarket"
          @update:options="searchMarkets"
          placeholder="Select Market"
          :auto-focus="true"
          :custom-width="true"
          :selected-label-display="'Market'"
        />
      </div>
      <div class="audit-log-filter">
        <Select
          :model-value="selectedSelections"
          :options="selectedMarketSelections"
          :multiselect="true"
          :disabled="!selectedMarket"
          placeholder="Selections"
          variant="terciary"
          @update:model-value="(updatedValue) => updateSelections(updatedValue)"
        />
      </div>
      <div
        class="audit-log-filter date"
        ref="auditLogDateFilter"
      >
        <div
          class="selected-time-range"
          :class="{
            'disabled': !selectedMarket,
            'has-error': hasDateTimeError
          }"
          :title="timeRangeLabel"
          @click="onTimeRangeToggle"
        >
          <div class="time-range-label">
            {{ timeRangeLabel }}
          </div>
          <Icon name="chevron-selector" />
        </div>
        <div
          class="audit-log-date-modal"
          v-if="timeRangeOpened"
        >
          <div class="form-group-header-label">
            FROM
          </div>
          <div :class="['audit-log-date-range__form-group', {'has-error':selectedFromTimeError}]">
            <div class="audit-log-date-range__form-label">
              Date:
            </div>
            <DatePicker
              class="audit-log-date-range__form-control"
              :model-value="selectedFromDate"
              @update:model-value="setSelectedFromDate"
              :disabled="!selectedMarket"
              hide-placeholder
            />
            <div class="audit-log-date-range__form-label">
              Time:
            </div>
            <TimeInput
              :model-value="selectedFromTime"
              @update:model-value="setSelectedFromTime"
              :error="selectedFromTimeError"
              :disabled="!selectedMarket || !selectedFromDate"
            />
          </div>
          <div
            class="audit-log-date-range__form"
            v-if="selectedFromTimeError"
          >
            <div
              v-if="selectedFromTimeError"
              class="audit-log-date-range__form-error"
            >
              <Icon
                v-if="selectedFromTimeError !== ' '"
                class="audit-log-date-range__form-error-icon"
                name="exclamation"
              />
              <span class="audit-log-date-range__form-error-message">
                {{ selectedFromTimeError }}
              </span>
            </div>
          </div>
          <div class="form-group-header-label">
            UNTIL
          </div>
          <div :class="['audit-log-date-range__form-group', {'has-error':selectedUntilTimeError}]">
            <div class="audit-log-date-range__form-label">
              Date:
            </div>
            <DatePicker
              class="audit-log-date-range__form-control"
              :model-value="selectedUntilDate"
              @update:model-value="setSelectedUntilDate"
              :disabled="!selectedMarket"
              hide-placeholder
            />
            <div class="audit-log-date-range__form-label">
              Time:
            </div>
            <TimeInput
              :model-value="selectedUntilTime"
              @update:model-value="setSelectedUntilTime"
              :error="selectedUntilTimeError"
              :disabled="!selectedMarket || !selectedUntilDate"
            />
          </div>
          <div
            class="audit-log-date-range__form"
            v-if="selectedUntilTimeError"
          >
            <div
              v-if="selectedUntilTimeError"
              class="audit-log-date-range__form-error"
            >
              <Icon
                v-if="selectedUntilTimeError !== ' '"
                class="audit-log-date-range__form-error-icon"
                name="exclamation"
              />
              <span class="audit-log-date-range__form-error-message">
                {{ selectedUntilTimeError }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="audit-log-filter toggle-wrapper">
        <div class="audit-log-filter-toggle">
          <span>In play</span>
          <Toggle
            :model-value="inPlayValue"
            :disabled="!selectedMarket"
            @update:model-value="updateInPlay"
          />
        </div>
      </div>
      <div class="divider" />
      <Button
        @click="applyFilters"
        :loading="dashboardLoading"
        :disabled="loadDataBtnDisabled"
        :variant="'primary'"
      >
        Load data
      </Button>
    </div>
    <Spinner v-if="dashboardLoading" />
    <div
      v-if="!dashboardLoading && marketWithLoadedPricingHistory && !showTutorialMessage"
      class="audit-log-body"
    >
      <h6 class="loaded-market-display-name">
        {{ marketWithLoadedPricingHistory?.displayName }}
      </h6>
      <div
        class="selection-iframe"
        v-for="selection in selectionUrls"
        :key="selection.id"
      >
        <div class="selection-iframe-label">
          {{ selection.displayName }}
        </div>
        <iframe
          :src="selection.url"
          height="514"
          width="100%"
          frameborder="0"
        />
      </div>
    </div>
    <div
      v-if="showTutorialMessage"
      class="no-loaded-market-history-tutorial"
    >
      <p>
        Please select Market, Selection, Time range and click on <b>Load data</b> to get data preview
      </p>
    </div>
  </div>
</template>

<script>
import {
  computed, ref, onMounted, onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import {
  split, join, reverse, orderBy, find, flatMap,
  filter, map, includes, toLower, isNil, replace,
  slice,
} from 'lodash';
import {
  format, parseISO, isBefore, addHours,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import MarketMapper from '@/services/helpers/market-mapper';
import Icon from '@/components/common/Icon';
import SearchSelect from '@/components/common/SearchSelect';
import Select from '@/components/common/Select';
import { convertTo24HourTime } from '@/services/helpers/time';
import DatePicker from '@/components/common/DatePicker';
import TimeInput from '@/components/common/TimeInput';
import Toggle from '@/components/common/Toggle';
import Spinner from '@/components/common/Spinner';
import Button from '@/components/common/Button';

export default {
  components: {
    Icon,
    SearchSelect,
    Select,
    DatePicker,
    TimeInput,
    Toggle,
    Spinner,
    Button,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const timeRangeOpened = ref(false);
    const searchMarketsValue = ref('');
    const selectedMarket = ref(null);
    const marketWithLoadedPricingHistory = ref(null);
    const selectedSelections = ref([]);
    const loadedMarketSelectedSelections = ref([]);
    const inPlayValue = ref(false);
    const isEventLoading = computed(() => store.getters.auditLogEventLoading);
    const dashboardLoading = computed(() => store.getters.auditLogMarketHistoryDashboardLoading);
    const loadedMarketFilterState = ref({});
    const showTutorialMessage = computed(() => (!dashboardLoading.value
    && !marketWithLoadedPricingHistory.value)
    || (selectedMarket.value?.marketId === marketWithLoadedPricingHistory.value?.marketId && loadedMarketSelectedSelections.value.length && !selectedSelections.value.length));

    const searchMarkets = (searchValue) => {
      searchMarketsValue.value = searchValue;
    };

    const updateSelections = (newValue) => {
      if (includes(selectedSelections.value, newValue)) {
        selectedSelections.value = filter(selectedSelections.value, (selection) => selection !== newValue);
        return;
      }
      selectedSelections.value.push(newValue);
    };

    const updateInPlay = () => {
      inPlayValue.value = !inPlayValue.value;
    };

    const event = computed(() => store.getters.auditLogEvent);
    const eventName = computed(() => {
      const competitors = split(event.value?.eventName, ' v ');
      if (event.value?.isUsaView) return join(reverse(competitors), ' @ ');
      return join(competitors, ' vs ');
    });

    const date = computed(() => {
      if (!event.value?.startsAt) return 'N/A';
      return format(zonedTimeToUtc(event.value?.startsAt, 'UTC'), 'MMM d, yyyy');
    });

    const time = computed(() => {
      if (!event.value?.startsAt) return 'N/A';
      return format(zonedTimeToUtc(event.value?.startsAt, 'UTC'), 'hh:mm a');
    });

    const homeTeam = computed(() => event.value?.competitors?.[0]);
    const awayTeam = computed(() => event.value?.competitors?.[1]);

    const auditLogEventMarkets = computed(() => store.getters.auditLogEventMarkets);

    const marketsDisplayConfiguration = computed(() => orderBy(filter(store.getters.displayMarketsConfiguration,
      (market) => market.active), 'position'));

    const filteredMarkets = computed(() => filter(auditLogEventMarkets.value,
      (market) => (includes(toLower(market.marketCode), searchMarketsValue.value)
        || includes(toLower(market.marketSummary?.playerName), searchMarketsValue.value)
        || (!isNil(market.marketType.params.TEAM) && includes(toLower(homeTeam.value?.name || ''), searchMarketsValue.value))
        || (!isNil(market.marketType.params.TEAM) && includes(toLower(awayTeam.value?.name || ''), searchMarketsValue.value))
        || includes(toLower(find(marketsDisplayConfiguration.value,
          (displayMarket) => displayMarket.marketCode === market.marketCode
            && displayMarket.selections === market.marketType.params.SELECTIONS)?.defaultName), searchMarketsValue.value))));

    const marketDisplayConfigurations = computed(() => filter(map(marketsDisplayConfiguration.value, (marketConfiguration) => ({
      ...marketConfiguration,
      markets: map(MarketMapper.sortSectionMarkets(filter(filteredMarkets.value, (market) => market.marketCode === marketConfiguration.marketCode
      && market.marketType.params.SELECTIONS === marketConfiguration.selections), event.value?.isUsaView), (filterMarket) => {
        const mappedMarket = MarketMapper.mapUngroupedMarket({
          marketDisplayConfiguration: marketConfiguration,
          market: filterMarket,
          homeTeam: homeTeam.value,
          awayTeam: awayTeam.value,
        });
        return {
          ...mappedMarket,
          selections: MarketMapper.sortSelections({ selections: filterMarket.selections, isUSAView: event.value?.isUsaView }),
        };
      }),
    })), (marketDisplayConfiguration) => marketDisplayConfiguration.markets?.length));

    const marketsList = computed(() => flatMap(marketDisplayConfigurations.value, (marketConf) => marketConf.markets));

    const setTeamNames = (selectionLabel) => {
      let replacedLabel = selectionLabel;
      const replacedLabebLowered = toLower(selectionLabel);
      if (includes(replacedLabebLowered, 'home') && replacedLabebLowered !== 'home run') {
        replacedLabel = replace(replacedLabebLowered, /home/g, homeTeam.value?.name);
      }
      if (includes(replacedLabebLowered, 'away')) {
        replacedLabel = replace(replacedLabebLowered, /away/g, awayTeam.value?.name);
      }
      if (includes(replacedLabel, '/')) {
        const labelSplited = split(replacedLabel, '/');
        replacedLabel = {
          twoPartLabel: true,
          leftSide: labelSplited[0],
          rightSide: labelSplited[1],
        };
      }
      return replacedLabel;
    };

    const remapSelectionCode = (selection) => {
      const selectionNameLabel = MarketMapper.selectionName(selection, { sportLabel: event.value?.sport?.sportLabel }, event.value?.isUsaView);
      return setTeamNames(selectionNameLabel);
    };

    const selectedMarketSelections = computed(() => {
      if (!selectedMarket.value) return [];
      return map(selectedMarket.value.selections, (selection) => {
        const selectionName = remapSelectionCode(selection);
        return {
          value: selection.selectionId,
          label: selectionName,
        };
      });
    });

    const loadedMarketSelections = computed(() => {
      if (!marketWithLoadedPricingHistory.value) return [];
      return map(marketWithLoadedPricingHistory.value.selections, (selection) => {
        const selectionName = remapSelectionCode(selection);
        return {
          value: selection.selectionId,
          label: selectionName,
        };
      });
    });

    const loadedMarketSelectionsToShow = computed(() => {
      if (selectedMarket.value?.marketId !== marketWithLoadedPricingHistory.value?.marketId) return map(loadedMarketSelections.value, (selection) => selection.value);
      return filter(
        loadedMarketSelectedSelections.value,
        (selection) => find(selectedSelections.value, (marketSelection) => marketSelection === selection),
      );
    });

    const selectionUrlsObj = computed(() => store.getters.auditLogEventMarketSelectionsUrls);
    const selectionUrls = computed(() => filter(map(loadedMarketSelectionsToShow.value, (selection) => {
      const foundSelection = find(loadedMarketSelections.value, (marketSelection) => marketSelection.value === selection);
      const selectionUrl = selectionUrlsObj.value?.[selection];
      return {
        id: selection,
        url: selectionUrl ? `${selectionUrl}#bordered=false&titled=false` : null,
        displayName: foundSelection?.label,
      };
    }), (selection) => !!selection.url));

    const auditLogDateFilter = ref(null);

    const selectedFromTime = ref('');
    const setSelectedFromTime = (newSelectedTime) => {
      selectedFromTime.value = newSelectedTime;
    };

    const selectedFromDate = ref('');
    const setSelectedFromDate = (newSelectedDate) => {
      selectedFromDate.value = newSelectedDate;
      if (!newSelectedDate) {
        selectedFromTime.value = '';
      }
    };

    const selectedFromTimeError = computed(() => {
      if (selectedFromDate.value && !selectedFromTime.value) return 'Time should be selected';
      const [selectedTime] = split(selectedFromTime.value, ' ');
      if (selectedTime === '00:00') return 'Invalid value. Did you mean 12:00?';
      if (!selectedTime) return '';
      const [hours, minutes] = split(selectedTime, ':').map(Number);
      const isValidTime = selectedFromTime.value?.length === 8 && hours > 0 && hours <= 12 && minutes >= 0 && minutes <= 59;
      if (!isValidTime) return 'Invalid value';
      return '';
    });

    const selectedUntilTime = ref('');
    const setSelectedUntilTime = (newSelectedTime) => {
      selectedUntilTime.value = newSelectedTime;
    };

    const selectedUntilDate = ref('');
    const setSelectedUntilDate = (newSelectedDate) => {
      selectedUntilDate.value = newSelectedDate;
      if (!newSelectedDate) {
        selectedUntilTime.value = '';
      }
    };

    const selectedUntilTimeError = computed(() => {
      if (selectedUntilDate.value && !selectedUntilTime.value) return 'Time should be selected';
      const [selectedTime] = split(selectedUntilTime.value, ' ');
      if (selectedTime === '00:00') return 'Invalid value. Did you mean 12:00?';
      if (!selectedTime) return '';
      const [hours, minutes] = split(selectedTime, ':').map(Number);
      const isValidTime = selectedUntilTime.value?.length === 8 && hours > 0 && hours <= 12 && minutes >= 0 && minutes <= 59;
      if (!isValidTime) return 'Invalid value';
      if (selectedFromDate.value) {
        const dateTimeFrom = parseISO(`${selectedFromDate.value}T${convertTo24HourTime(selectedFromTime.value)}:00`);
        const dateTimUntil = parseISO(`${selectedUntilDate.value}T${convertTo24HourTime(selectedUntilTime.value)}:00`);
        if (isBefore(dateTimUntil, dateTimeFrom)) return 'Date until cannot be before the date from';
      }
      return '';
    });

    const hasDateTimeError = computed(() => !!selectedFromTimeError.value || !!selectedUntilTimeError.value);

    const filtersChanged = computed(() => selectedMarket.value?.marketId !== loadedMarketFilterState.value.selectedMarket?.marketId
        || selectedFromDate.value !== loadedMarketFilterState.value.from
        || selectedUntilDate.value !== loadedMarketFilterState.value.to
        || selectedFromTime.value !== loadedMarketFilterState.value.timeFrom
        || selectedUntilTime.value !== loadedMarketFilterState.value.timeTo
        || inPlayValue.value !== loadedMarketFilterState.value.inPlay);

    const loadDataBtnDisabled = computed(() => !selectedMarket.value || hasDateTimeError.value || !filtersChanged.value);

    const clearFilters = () => {
      selectedFromDate.value = '';
      selectedFromTime.value = '';
      selectedUntilDate.value = '';
      selectedUntilTime.value = '';
      inPlayValue.value = false;
    };

    const selectMarket = (market) => {
      selectedMarket.value = market;
      // If market has more than 3 selections select first 3 by default
      // Otherwise select all
      if (market?.selections?.length > 3) {
        selectedSelections.value = map(slice(market.selections, 0, 3), 'selectionId');
      } else if (market?.selections?.length) {
        selectedSelections.value = map(market.selections, 'selectionId');
      } else {
        selectedSelections.value = [];
      }

      clearFilters();
    };

    const timeRangeLabel = computed(() => (selectedFromDate.value
      ? `Time range: ${selectedFromDate.value} ${selectedFromTime.value || ''} - ${selectedUntilDate.value || ''} ${selectedUntilTime.value || ''}`
      : 'Time range'
    ));

    const applyFilters = () => {
      let query = {};
      if (loadDataBtnDisabled.value) return;
      if (selectedFromDate.value && selectedFromTime.value && !selectedFromTimeError.value) {
        const dateTime = parseISO(`${selectedFromDate.value}T${convertTo24HourTime(selectedFromTime.value)}:00`);
        const currentDate = new Date();
        const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
        const utcFromTime = format(addHours(dateTime, timezoneHoursOffset), 'yyyy-LL-dd\'T\'HH:mm:ss');
        query = { from: utcFromTime };
      }
      if (selectedUntilDate.value && selectedUntilTime.value && !selectedUntilTimeError.value) {
        const dateTime = parseISO(`${selectedUntilDate.value}T${convertTo24HourTime(selectedUntilTime.value)}:00`);
        const currentDate = new Date();
        const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
        const utcToTime = format(addHours(dateTime, timezoneHoursOffset), 'yyyy-LL-dd\'T\'HH:mm:ss');
        query = { ...query, to: utcToTime };
      }
      if (inPlayValue.value) {
        query = { ...query, inPlay: inPlayValue.value };
      }
      const currentDate = new Date();
      const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
      const timeOffset = timezoneHoursOffset <= 0 ? Math.abs(timezoneHoursOffset) : -timezoneHoursOffset;
      store.dispatch('loadMarketHistoryDashboard', {
        marketId: selectedMarket.value.marketId,
        timezone: timeOffset,
        query,
      }).then(() => {
        loadedMarketSelectedSelections.value = selectedSelections.value;
        marketWithLoadedPricingHistory.value = selectedMarket.value;
        loadedMarketFilterState.value = {
          selectedMarket: selectedMarket.value,
          from: selectedFromDate.value,
          to: selectedUntilDate.value,
          inPlay: inPlayValue.value,
          timeFrom: selectedFromTime?.value,
          timeTo: selectedUntilTime?.value,
        };
      });
    };

    const onTimeRangeToggle = () => {
      if (!selectedMarket.value) return;
      timeRangeOpened.value = !timeRangeOpened.value;
    };

    onClickOutside(auditLogDateFilter, () => {
      timeRangeOpened.value = false;
    });

    onMounted(() => {
      store.dispatch('loadAuditLogEvent', route.params.eventId);
    });

    onUnmounted(() => {
      store.dispatch('clearAuditLogEvent');
    });
    return {
      date,
      time,
      eventName,
      marketsList,
      selectedMarket,
      selectMarket,
      searchMarkets,
      selectedSelections,
      selectedMarketSelections,
      updateSelections,
      selectionUrls,
      selectedFromDate,
      setSelectedFromDate,
      selectedFromTime,
      setSelectedFromTime,
      selectedFromTimeError,
      selectedUntilDate,
      setSelectedUntilDate,
      selectedUntilTime,
      setSelectedUntilTime,
      selectedUntilTimeError,
      timeRangeOpened,
      auditLogDateFilter,
      inPlayValue,
      updateInPlay,
      isEventLoading,
      onTimeRangeToggle,
      applyFilters,
      hasDateTimeError,
      dashboardLoading,
      filtersChanged,
      loadDataBtnDisabled,
      marketWithLoadedPricingHistory,
      showTutorialMessage,
      timeRangeLabel,
    };
  },
};
</script>

<style lang="scss">
.audit-log {
  width: 100%;
  height: calc(100% - 69px);
  display: flex;
  flex-direction: column;

  .audit-log-header {
    min-height: 64px;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    border-bottom: 1px solid #F0F0F0;
    gap: 2px;

    .audit-log-header-info {
      height: 14px;
      color: #6D6D6D;
      display: flex;
      align-items: center;

      .audit-log-header-info-text {
        display: flex;
        height: 100%;
        align-items: center;

        .icon {
          margin-right: 4px;
        }
      }

      .dot-separator {
        margin: 0 4px;
      }
    }
  }

  .audit-log-filters-wrapper {
    min-height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;

    .audit-log-filter {
      height: 32px;
      width: 240px;

      .icon {
        stroke-width: 0;
      }

      &.date {
        position: relative;

        .selected-time-range {
          width: 240px;
          height: 32px;
          display: flex;
          justify-content: space-between;
          border: 1px solid #F0F0F0;
          border-radius: 4px;
          align-items: center;
          padding: 0 8px;
          cursor: pointer;

          &.disabled {
            background-color: #F0F0F0;
            color: #A9A9A9;
            cursor: not-allowed;
          }
          &.has-error {
            border: 1px solid $error500;
          }

          .time-range-label {
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &.toggle-wrapper {
        width: unset;
      }

      .audit-log-filter-toggle {
        display: flex;
        gap: 4px;
        height: 100%;
        align-items: center;

        .toggle {
          position: relative;
          top: 1px;
        }
      }
    }
    .divider {
      width: 1px;
      height: 32px;
      background-color: $gray400;
      margin: 0 16px;
    }
  }

  .audit-log-body {
    padding: 16px;
    width: 100%;
    height: calc(100% - 64px - 64px);
    overflow-y: auto;

    .loaded-market-display-name {
      margin-bottom: 16px;
    }

    .selection-iframe {
      width: 100%;
      border: 1px solid #F0F0F0;
      border-radius: 8px;
      margin-bottom: 8px;

      .selection-iframe-label {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
        padding: 16px 16px 0 16px;
      }
    }
  }
  .no-loaded-market-history-tutorial {
    color: #323232;
    max-width: 287px;
    padding-top: 156px;
    text-align: center;
    margin: 0 auto;
  }
}

.audit-log-date-range {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $modalZIndex;
  pointer-events: all;

  .select {
    .select-dropdown {
      max-height: 200px;

      .select-dropdown-item {
        flex-shrink: 0;
      }
    }
  }

  &__frame {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 6px;
    cursor: default;
    width: 496px;
  }

  &__form {
    &-group {
      display: flex;
      gap: 6px;
      align-items: center;
      margin-bottom: 16px;

      &.has-error {
        margin-bottom: 4px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .date-picker {
        height: 32px;
        max-height: 32px;
        min-height: 32px;
      }

      .time-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        height: 32px;

        .select-input {
          width: 72px;
        }

        &__control {
          display: flex;
          align-items: center;
          background-color: $white;
          border: $textInputBorder;
          border-radius: $textInputBorderRadius;
          width: 96px;
          height: 100%;
          transition: $textInputBoxShadowTransition;
          padding: 0;

          &:not(&--is-invalid):focus-within {
            outline: 1px solid #a2bfff;
          }

          &--is-invalid {
            outline: 1px solid $error500;
          }
        }

        &__input {
          width: 100%;
          height: 100%;
          padding: 0 8px;

          &:focus {
            border: none;
            outline: none;
          }

          &::placeholder {
            color: #CDCDCD;
          }
        }
      }
    }

    &-label {
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize;
      color: #191414;
    }

    &-control {
      width: 100px;
    }

    &-error {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 10px;
      color: $error500;
      margin-top: 4px;
      margin-bottom: 8px;

      &-icon {
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        overflow: hidden;

        svg {
          background-color: $error500;
          fill: #fff;
          width: 100%;
          height: 100%;
        }
      }

      &-message {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.audit-log-date-modal {
  position: absolute;
  top: 34px;
  left: 0;
  width: 415px;
  min-height: 100px;
  background-color: #fff;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px #19141414;
  padding: 8px 16px;

  .form-group-header-label {
    font-size: 10px;
    color: #6D6D6D;
    font-weight: 600;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 4px;
  }
}
</style>
